import React, { useEffect } from "react"
import { Layout } from "@/layout"
import { Box, SEO, Heading, Text, Width, CTA } from "@/components"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import localStorage from "@/utils/localStorage"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoints } from "@/theme/index"
import $ from "jquery"

export default function Application({ data }) {
  const location = useLocation()
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""
  let referrerId = localStorage.get("referrer_id")
  let refParam = urlParameters.query?.referrer_id
  if (referrerId && !refParam) {
    navigate(`/application?referrer_id=` + referrerId)
  }

  useEffect(() => {
    window.jQuery = $
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: "6200944",
        formId: "7b8d94fc-dd09-4340-9ff3-92304fddc42f",
        target: "#hubspotForm",
        onFormSubmit: function ($form) {
          console.log("ON FORM SUBMITTTT", $form)
          var level = $form.find(
            'select[name="where_would_you_rate_your_guitar_playing_"]'
          )[0].value
          var playingTime = $form.find(
            'select[name="how_long_have_you_played_the_guitar"]'
          )[0].value

          var doesntHaveResources = $form.find(
            'input[name="the_guitar_mastery_intensive_is_a_significant_financial_investment_in_your_growth_as_a_musician_are"]:checked'
          )[0].value
          console.log(doesntHaveResources, level, playingTime)
          if (
            level ==
            "Beginner" || playingTime == "< 6 months"
          ) {
            window.localStorage.setItem("redirectUrl", "/beginner-path")
          } else if (
            doesntHaveResources ==
            "I don't have any additional finances to invest right now."
          ) {
            window.localStorage.setItem("redirectUrl", "/application-submitted")
          } else {
            window.localStorage.setItem("redirectUrl", "/schedule")
          }
        },
        onFormSubmitted: function () {
          console.log("USING THIS")
          var url = window.localStorage.getItem("redirectUrl")
          window.location.href = url
        },
      })
    }
  }, [])

  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="Let's talk about your guitar playing"
        description="Your 50-minute Free Discovery Session is the next step to realizing your dreams for your guitar playing."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection={["column", "row"]}
          pt={["1.2rem", null, null, "5.2rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", null, null, 6 / 12]}
            textAlign="left"
            mx="auto"
            pr={["2rem", "2rem", "2rem", "3rem"]}
          >
            <Heading level="xl" mb="1rem" mt={["4rem", "5rem", "5rem", "5rem"]}>
              Let's talk about your guitar playing.
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Your <strong>50-minute Free Discovery Session</strong> is the next
              step to realizing your dreams for your guitar playing.
            </Text>
            <Text
              level="2"
              mb={["5rem", "5rem", "5rem", "5rem"]}
              mt="3rem"
              pr={["0rem", "2rem", "2rem", "4rem"]}
            >
              The Sonora Guitar Intensive program is designed for intermediate
              guitarists who want to break out of a long plateau and find{" "}
              <strong>full creative fluency</strong> on the instrument, under
              the direct guidence of some of the world's best musicians.
            </Text>
            <HideCard>
              <Box mb="3em">
                <CTA variant="special" to="#survey">
                  {" "}
                  Get Started
                </CTA>
              </Box>
            </HideCard>
            <Box>
              <Box
                width={["100%", null, 12 / 12, null]}
                display="flex"
                flexDirection="row"
                mt={["0rem", null, null, "3rem"]}
              >
                <Box width={[3 / 12, null]} pr="2rem">
                  <Box borderRadius="100%" overflow="hidden">
                    <Img
                      alt={"david"}
                      fluid={data.davidEImage.childImageSharp.fluid}
                    />
                  </Box>
                </Box>
                <Text
                  width={["100%", null, 9 / 12, null]}
                  level="2"
                  mb="1rem"
                  pr={["2rem", "2rem", "2rem", "4rem"]}
                >
                  "This is the most thoughtful, innovative, and intelligent
                  methodology for internalizing not only fretboard fluency, but
                  music in general." <br></br>
                  <strong>- David Engelhardt, Sonora Alumni</strong>
                </Text>
              </Box>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"milton"}
                    fluid={data.miltonPImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "This guitar learning program is by far the best one ever
                conceptualized." <br></br>
                <strong>- Milton Pizarro, Sonora Alumni</strong>
              </Text>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"david"}
                    fluid={data.erikLImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "I went from someone who can't improvise at all to being able to
                freely play and improvise over any chord changes in 3 months."{" "}
                <br></br>
                <strong>- Erik Luo, Sonora Alumni</strong>
              </Text>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
              mb="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"kevin"}
                    fluid={data.katieBImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "It was an incredibly worthwhile investment that helped me break
                out of a years long rut as a writer and player." <br></br>
                <strong>- Katie Boeck, Sonora Alumni</strong>
              </Text>
            </Box>
          </Box>

          <Box width={["90%", null, null, 6 / 12]} id="survey" mx="auto">
            <ApplicationCard>
              <Heading level="2" mb={["5rem", null, null, "2rem"]}>
                Tell us about yourself
              </Heading>
              <div id="hubspotForm"></div>
            </ApplicationCard>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}

const ApplicationCard = styled.div`
  background-color: white;
  border-radius: 0.5em;
  padding: 1em;
`
const HideCard = styled.div`
  @media (min-width: ${breakpoints[1]}) {
    display: none;
  }
`

/* Image and Other GQL Queries */
export const applicationQuery = graphql`
  query {
    davidEImage: file(relativePath: { eq: "davide.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    kevinSImage: file(relativePath: { eq: "kevins.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    miltonPImage: file(relativePath: { eq: "miltonp.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    katieBImage: file(relativePath: { eq: "katieb.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    erikLImage: file(relativePath: { eq: "erikl.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
